import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  //Switch,
  //Redirect,
} from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";

import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.2.0";
import "./assets/demo/demo.css";

import 'bootstrap-css-only/css/bootstrap.min.css';
//import 'mdbreact/dist/css/mdb.css';

import { setCurrentUser } from "./actions/authActions";
import {setTaskStatus} from "./actions/taskActions";
import { setScreenShot } from "./actions/urlScreenShotActions";
import { Provider } from "react-redux";
import store from "./store";

import LoginAzure from "./components/public/LoginAzure";
import CheckAzure from "./components/public/CheckAzure";
import Register from "./components/public/Register";
import Home from "./components/public/Home";
import StaticRoutes from "./components/staticreports/PublicLayout";
import Login from "./components/public/Login";
import NotFound from "./components/public/NotFound";
import { connect } from 'react-redux';
import Internal from "./components/layout/Internal";
import PrivateRoute from "./components/private-route/PrivateRoute";
import * as Icons from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Landing from "./components/public/Landing";
import Logout from "./components/public/Logout";
import axios from "axios";
import { w3cwebsocket as W3CWebSocket } from "websocket";

axios.defaults.prefix = "/api/"

const iconList = Object.keys(Icons)
  .filter((key) => key !== "fas" && key !== "prefix")
  .map((icon) => Icons[icon]);

library.add(...iconList);

const logoutUser = () => {

  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  store.dispatch(setCurrentUser({}));
};

// added for AP integration start
const params = new URLSearchParams(window.location.search);
const token = params.get("tokenId")
var decoded = null;

if (token) {
  try {
    decoded = jwt_decode(token);
    if (decoded.full_name) {
      localStorage.setItem("jwtToken", token);
    }
  } catch (err){
    logoutUser();
  }
}
// added for AP integration end
// Check for token to keep user logged in
if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
 
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds



  const callWEbSocket = ()=>{
    const ws = new WebSocket(
      "wss://ia1ubbz306.execute-api.us-east-1.amazonaws.com/production"
    );
    ws.onopen = (event) => {
      var test = { action: "senMessage", data: decoded.id };
      ws.send(JSON.stringify(test));
      console.log("WebSocket connection opened");
    };
    ws.onmessage = (event) => {
      if (event.data !== "") {
       const innerData = JSON.parse(event.data);
       // const innerData = JSON.parse(innerJsonString);
         if(innerData.status) {
        store.dispatch(setTaskStatus(innerData));
         }
         else {
             store.dispatch(setScreenShot(innerData));
         }
      }
    };
    ws.onclose = () => {
      console.log("WebSocket connection closed");
      callWEbSocket()
      console.log("WebSocket connection Reopened");
    };
  }
   callWEbSocket()
  if (decoded.exp < currentTime) {
    // Logout user
    logoutUser();

    // Redirect to login
   window.location.href = "./login";
  }
}
class App extends Component {
  render() {
    const { urlParams } = this.props;
    return (
      <Provider store={store}>
      <div className="App">
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/connect/azure" element={<LoginAzure />} />
            <Route path="/check/azure" element={<CheckAzure />} />
            <Route path="/404" element={<NotFound />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/staticreports" element={<StaticRoutes />} />
            <Route path='*' element={<Internal/>} />
            <Route render={() => <Navigate to="/404" />} />
          </Routes>
        </Router>
      </div>
     </Provider>
    );
  }
}
const mapStateToProps = (state) =>
 {
  return { urlParams: state.currentUrl };
}
;
export default connect(mapStateToProps)(App);
